import { CommonModule } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { CenterStore } from '../../../stores/center.store';
import { CenterDto } from '@malirang/api-client';

@Component({
  selector: 'app-center-role-badge',
  template: `
    @if (role(); as role) {
      <div class="text-gray-500 bg-gray-100 px-2 py-1 text-xs rounded-lg">
        {{ role.name }}
      </div>
    } @else {
      <div class=" px-2 py-1 text-xs rounded-lg bg-neutral-300 text-white ">
        미소속
      </div>
    }
  `,
  imports: [CommonModule],
})
export class CenterRoleBadgeComponent {
  center = input<CenterDto>();

  readonly centerStore = inject(CenterStore);

  myRole = this.centerStore.myRole;

  /**
   * props로 받은 center가 있으면 center의 role을 반환
   * 없으면 store의 role을 반환
   */
  role = computed(() => {
    if (this.center()) {
      return this.center()?.myRole;
    }

    return this.myRole();
  });
}
